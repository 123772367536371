import { Options, Vue } from "vue-class-component";
import './index.scss'
import bgAvatar from './assets/logo.png'
import { h } from "@vue/runtime-dom";
import { RouterLink, RouterLinkProps, RouterView } from "vue-router";
import { ElDialog, ElNotification } from "element-plus";
import { getFile } from "@/utils";
import { mapGetters } from "vuex";
import DYCropper from "./components/DYCropper";
import { UserInfoRow, UserTeamRow } from "@/store/modules/user";
import api from "@/api";
import { ListOpions } from "@/models/type";
import { getToken } from "@/utils/auth";
@Options({
  computed: {
    ...mapGetters(['GetBackgroundImgUrlTop', 'GetUserInfo', 'GetUserTeam'])
  }
})
export default class Mine extends Vue {

  public GetBackgroundImgUrlTop?: string // 动态背景
  public GetUserInfo?: UserInfoRow // 用户信息
  public GetUserTeam?: UserTeamRow // 团队信息
  private backgroundImgUrl = '' // 初始动态背景图片
  private newBackgroundImgUrl = '' // 新背景
  public avatarShow = false
  public avatarImg = '' // 初始头像
  public file?: File
  public memberLevelList: Array<ListOpions> = [] // 会员等级列表
  public vipShow = false // vip弹出框

  private linkList: Array<{ label?: string, props: RouterLinkProps }> = [
    { label: '我的课程', props: { to: '/mine/subject', activeClass: 'mine-isActive' } },
    { label: '我的活动', props: { to: '/mine/activity', activeClass: 'mine-isActive' } },
    { label: '订单', props: { to: '/mine/order', activeClass: 'mine-isActive' } },
    { label: '账户设定', props: { to: '/mine/site', activeClass: 'mine-isActive' } },
  ]
  private teamLinkList: Array<{ label?: string, props: RouterLinkProps }> = [
    { label: '账户信息', props: { to: '/mine/team_info', activeClass: 'mine-isActive' } },
    { label: '团队管理', props: { to: '/mine/team', activeClass: 'mine-isActive' } },
  ]
  private vipLinkList: Array<{ label?: string, props: RouterLinkProps }> = [
    { label: '我的课程', props: { to: '/mine/subject', activeClass: 'mine-isActive' } },
    { label: '我的活动', props: { to: '/mine/activity', activeClass: 'mine-isActive' } },
    { label: '订单', props: { to: '/mine/order', activeClass: 'mine-isActive' } },
    { label: '代金券', props: { to: '/mine/voucher', activeClass: 'mine-isActive' } },
    { label: '积分商城', props: { to: '/mine/integral', activeClass: 'mine-isActive' } },
    { label: '账户设定', props: { to: '/mine/site', activeClass: 'mine-isActive' } },
  ]
  public bgChangeBool = false;

  /**
   * @description 用户信息
   * @author Jinx
   * @date 2021-10-12 15:02:58
   * @returns {*}  {JSX.Element}
   * @memberof Mine
   */
  info(): JSX.Element {
    const nickname = this.GetUserInfo?.username || this.GetUserInfo?.nickname || this.GetUserInfo?.email || this.GetUserInfo?.phone || '用户名'
    return <div class="mine-info" onClick={this.avatarChange}>
      <img src={ this.GetUserInfo?.icon || bgAvatar } alt={ nickname } />
      <div style={{
        display: 'flex',
        flexFlow: 'column'
      }}>
        <span>{ nickname }</span>
        {
          this.memberLevelName && this.memberLevelName !== '普通用户'
            && <span class="point">剩余积分：{ this.GetUserInfo?.integration }</span>
        }
      </div>
    </div>
  }

  avatarChange(): void {
    this.avatarImg = this.GetUserInfo?.icon || ''
    this.avatarShow = true
  }

  /**
   * @description 身份
   * @author Jinx
   * @date 2021-10-12 15:38:33
   * @returns {*}  {JSX.Element}
   * @memberof Mine
   */
  identity(): JSX.Element {
    return <div class="mine-identity">
      <span class="mine-title">用户身份</span>
      <div class="mine-identity-content">
        <span class={['mine-identity-content_title', this.memberLevelName !== '普通用户' ? 'pointer' : '']}
          onClick={this.goVip}
        >
          {this.memberLevelName === 'DIY会员' ? this.GetUserInfo?.attr : this.memberLevelName}
        </span>
        <i class="el-icon el-icon-arrow-right"/>
        {this.memberLevelName === '普通用户' && <i class="mine-identity-content_vip" onClick={ this.toVip }>成为会员</i>}
      </div>
    </div>
  }

  goVip(): void {
    if(this.memberLevelName === '普通用户') {
      return
    }
    this.$router.push({
      name: 'Vip'
    })
  }

  /**
   * @description 成为会员
   * @author Jinx
   * @date 2021-11-04 10:00:59
   * @memberof Mine
   */
  toVip(): void {
    this.vipShow = true
  }

  get memberLevelName(): string | undefined {
    const memberLevelId = this.GetUserInfo?.memberLevelId
    return this.memberLevelList.find(item => item.value === memberLevelId)?.name
  }

  /**
   * @description 机构
   * @author Jinx
   * @date 2021-10-12 15:38:44
   * @returns {*}  {JSX.Element}
   * @memberof Mine
   */
  agency(): JSX.Element {
    return <div class="mine-agency">
      <span class="mine-title">所属机构</span>
      {
        this.GetUserInfo?.memberLevelId === 1
          ? <div>{this.GetUserInfo.institution}</div>
          : <div>{this.GetUserTeam?.title}</div>
      }

    </div>
  }

  bgEdit(): JSX.Element {
    return <div class="mine-bg_edit" onClick={this.backgroundChange}>更换背景<i class="el-icon el-icon-arrow-right"/></div>
  }

  backgroundChange(): void {
    this.backgroundImgUrl = this.GetUserInfo?.bgImg as string
    this.bgChangeBool = true
  }
  backgroundChangeSubmit(): void {
    const formData = new FormData()
    formData.append('file', this.file as File)
    api.ossUpload(formData).then(res => {
      api.editBgImage({
        bgImage: res.data
      }).then(() => {
        ElNotification({
          type: 'success',
          title: '保存成功'
        })
        this.$store.dispatch('changeImgUrl', res.data)
        this.bgChangeBool = false
      })
    })
  }
  backgroundChangeClose(): void {
    this.$store.dispatch('changeImgUrl', this.backgroundImgUrl)
    this.newBackgroundImgUrl = ''
    this.bgChangeBool = false
  }
  public backgroundImg(): JSX.Element {
    return <ElDialog
      modelValue={ this.bgChangeBool }
      beforeClose={ this.backgroundChangeClose }
      customClass="mine-background"
      width="564px"
      showClose={false}
      closeOnClickModal={false}
    >
      {this.newBackgroundImgUrl || this.backgroundImgUrl
        ? <img
            src={ this.newBackgroundImgUrl || this.backgroundImgUrl } alt=""
          />
        : <div class="mine-background-img"/>}
      <h3>个人主页封面</h3>
      <span>规格：JPG / GIF / PNG，RGB模式，3M以内</span>
      <div class="mine-background-upload" onClick={this.uploadFile}>上传图片</div>
      <div class="mine-background-bottom">
        <div class="mine-background-submit" onClick={this.backgroundChangeSubmit}>保存</div>
        <div class="mine-background-cancel" onClick={this.backgroundChangeClose}>取消</div>
      </div>
    </ElDialog>
  }

  vipClose(): void {
    this.vipShow = false
  }

  vipDom(): JSX.Element {
    return <ElDialog
      modelValue={ this.vipShow }
      beforeClose={ this.vipClose }
      customClass="mine-vip"
      width="400px"
      showClose={false}
      closeOnClickModal={false}
    >
      <div class="vip-content">
        <div class="vip-content-title">加入我们</div>
        <div class="vip-content-intrduce">享受更多专属福利</div>
        <div class="vip-content-border" />
        <div class="vip-content-list"><i class="el-icon-check"/><span>活動課程費用減免福利</span></div>
        <div class="vip-content-list"><i class="el-icon-check"/><span>购买商品享有折扣优惠</span></div>
        <div class="vip-content-list"><i class="el-icon-check"/><span>积累会员积分兑换礼品</span></div>
        <div class="vip-content-btn">
          <div class="pointer" onClick={this.vipClose}>再考虑下</div>
          <div class="pointer" onClick={this.changeVip}>成为会员</div>
        </div>
      </div>
    </ElDialog>
  }

  /**
   * @description 成为会员
   * @author Jinx
   * @date 2021-11-04 10:34:43
   * @memberof Mine
   */
  changeVip(): void {
    this.$router.push({
      name: 'Vip'
    })
  }

  backDom(): JSX.Element {
    return <div
      class="mine-back"
      onClick={this.goMobile}
    >
      <i class="el-icon-arrow-left"></i>
    </div>
  }

  goMobile(): void {
    // TODO 跳转移动端
    window.location.href = 'http://mobile.taosseract.com/?token=' + getToken()
  }

  uploadFile(): void {
    getFile((file: Array<File>) => {
      const __file = file[0]
      if(__file.size > 3145728) {
        ElNotification({
          type: 'warning',
          message: '图片大小不能大于3M'
        })
        return
      }
      const url = URL || webkitURL
      const imgUrl = url.createObjectURL(__file)
      this.file = __file
      this.newBackgroundImgUrl = imgUrl
      // 预览一下
      this.$store.dispatch('changeImgUrl', imgUrl)
    }, 'image/*')
  }

  bg(): JSX.Element {
    return h('div', {
      class: 'mine-bg',
      style: {
        background: this.GetBackgroundImgUrlTop
      }
    }, [
      this.info(),
      Boolean(this.GetUserInfo?.memberLevelId) && this.identity(),
      Boolean(this.GetUserInfo?.teamId || this.GetUserInfo?.memberLevelId === 1) && this.agency(),
      this.bgEdit(),
      this.backDom()
    ])
  }

  link(): JSX.Element {
    let arr: Array<{ label?: string, props: RouterLinkProps }> = []
    if(this.memberLevelName === '普通用户') {
      arr = this.linkList
    } else if(!this.memberLevelName) {
      arr = this.teamLinkList
    } else {
      arr = this.vipLinkList
    }
    return h('ul', {
      class: 'mine-link'
    }, arr.map(item => <li class="mine-link-cell">
      <RouterLink { ...item.props }>
        <span>{ item.label }</span>
        <span class="border" />
      </RouterLink>
    </li>))
  }

  async cropperClose(): Promise<void> {
    await this.$store.dispatch('getUserInfo')
    this.avatarImg = this.GetUserInfo?.icon || ''
    this.avatarShow = false
  }

  cropperUploadEnd(url: string): void {
    const __cropper = this.$refs.cropper as DYCropper
    this.avatarImg = ''
    __cropper.myCropper = null
    this.avatarImg = url
    this.$nextTick(() => {
      __cropper.init()
    })
  }

  cropperResetUpload(): void {
    const __cropper = this.$refs.cropper as DYCropper
    this.avatarImg = ''
    __cropper.myCropper = null
    getFile((file: Array<File>) => {
      const uploadFile = file[0]
      const url = window.URL || window.webkitURL
      this.avatarImg = url.createObjectURL(uploadFile)
      this.$nextTick(() => {
        __cropper.init()
      })
    }, 'image/*')
  }

  getData(): void {
    Promise.all([
      api.memberLevelList()
    ]).then(res => {
      this.memberLevelList = res[0].data.map((item: any) => ({name: item.name, value: item.id}))
    })
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return <div class="mine">
      {this.bg()}
      {this.link()}
      {this.backgroundImg()}
      {this.vipDom()}
      <RouterView/>
      {this.avatarShow && h(DYCropper, {
        ref: 'cropper',
        show: this.avatarShow,
        avatar: this.avatarImg,
        onClose: this.cropperClose,
        onUploadEnd: this.cropperUploadEnd,
        onResetUpload: this.cropperResetUpload
      })}
    </div>
  }
}
