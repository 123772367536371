import { Options, Prop, Vue } from "vue-property-decorator";
import 'cropperjs/dist/cropper.css'
import './cropper.scss'
import Cropper from "cropperjs";
import { base64ToFile, getFile } from "@/utils";
import api from "@/api";
import { ElNotification } from "element-plus";

@Options({
  emits: ['uploadEnd', 'close', 'resetUpload']
})
export default class DYCropper extends Vue {
  @Prop({default: false, type: Boolean}) readonly show!:boolean
  @Prop({default: '', type: String}) readonly avatar!: string
  myCropper?: any
  afterImg = ''
  ratio = 0

  init() {
    if(!this.avatar) {
      return
    }
    this.myCropper = new Cropper(this.$refs.image as any, {
      viewMode: 1,
      dragMode: 'move',
      initialAspectRatio: 1,
      aspectRatio: 1,
      preview: '.before',
      background: true,
      autoCropArea: 0.6,
      zoomOnWheel: true,
      ready: () => this.sureSava(),
      cropstart: () => this.sureSava(),
      cropmove: () => this.sureSava(),
      cropend: () => this.sureSava(),
      crop: () => this.sureSava(),
      zoom: () => this.sureSava()
    })
  }

  sureSava(){
    this.$nextTick(() => {
      this.afterImg = this.myCropper.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/jpeg')
    })
  }

  close(): void {
    this.myCropper = null
    this.$emit('close')
  }
  zoom(number: number) {
    if(number > 0) {
      this.myCropper.zoom(0.1)
    } else {
      this.myCropper.zoom(-0.1)
    }
  }
  rotate() {
    this.myCropper.rotate(90)
  }

  /**
   * @description 上传完成的时间
   * @author Jinx
   * @date 2021-10-15 10:25:11
   * @memberof DYCropper
   */
  firstUpload(): void {
    getFile((file: Array<File>) => {
      const uploadFile = file[0]
      const url = window.URL || window.webkitURL
      const uploadUrl = url.createObjectURL(uploadFile)
      this.$emit('uploadEnd', uploadUrl)
    }, 'image/*')
  }

  resetUpload(): void {
    this.afterImg = ''
    this.$emit('resetUpload')
  }

  submit(): void {
    const file = base64ToFile(this.afterImg, 'logo.png')
    const formData = new FormData()
    formData.append('file', file)
    api.ossUpload(formData).then(res => {
      if(res.data) {
        api.editAvatar({
          avatar: res.data
        }).then(() => {
          ElNotification({
            type: 'success',
            message: '设置成功'
          })
          this.$emit('close')
        })
      }
    })
  }

  render(): JSX.Element {
    const slot = {
      footer: () => <div class="cropper-submit">
        <div class="cropper-submit-yes" onClick={this.submit}>确定</div>
        <div class="cropper-submit-no" onClick={this.close}>取消</div>
      </div>
    }
    return <el-dialog
        title="上传头像"
        v-model={this.show}
        width="550px"
        before-close={this.close}
        custom-class="cropper"
        v-slots={slot}
        onOpened={this.init}
      >
        <div class="container">
          <div class="container-bottom">
            {
              this.avatar
                ?  <div class="container-bottom-reset" onClick={this.resetUpload}>重新上传</div>
                :  <div class="container-bottom-first" onClick={this.firstUpload}>点击上传</div>
            }
            {
              this.avatar && <div class="container-container-bottom-right">
              <div class="el-icon el-icon-circle-plus-outline" onClick={() => this.zoom(1)}></div>
              <div class="el-icon el-icon-remove-outline" onClick={() => this.zoom(-1)}></div>
              <div class="el-icon el-icon-refresh-right" onClick={this.rotate}></div>
            </div>
            }
          </div>
          {
             this.avatar && <div class="img-container">
              <img src={ this.avatar } ref="image" alt="" />
            </div>
          }
          {
            this.afterImg &&  <div class="afterCropper">
              <img src={this.afterImg} alt="" />
              <div class="afterCropper-text1">头像预览</div>
              <div class="afterCropper-text2">审核通过后展示</div>
            </div>
          }
        </div>
    </el-dialog>
  }
}
